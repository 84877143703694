import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { FlexCardImages, CardImages, CardImageWrapper } from './styled'

const ReferanceImages = () => {
  const data = useStaticQuery(graphql`
    {
      allDataYaml {
        edges {
          node {
            referances {
              imageAlt
              landing
              image {
                childImageSharp {
                  fixed(width: 100, quality: 100) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
              title
              companyUrl
            }
          }
        }
      }
    }
  `)

  const referancesData = data.allDataYaml.edges[1].node.referances
  return (
    <FlexCardImages>
      {referancesData.map(item => (
        <CardImageWrapper key={item.id}>
          <a
            href={item.companyUrl || null}
            target="_blank"
            rel="noopener"
            aria-label={item.title}
            title={item.title}
          >
            <CardImages
              fixed={item.image.childImageSharp.fixed}
              alt={item.imageAlt}
            />
          </a>
        </CardImageWrapper>
      ))}
    </FlexCardImages>
  )
}

export default ReferanceImages
