import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import useTranslations from '../components/useTranslations'
import * as S from '../components/Referances/styled'
import Images from '../components/Referances/images'
import DocumentDownload from "../components/LandingPage/documentDownload"

const Referances = ({ data }) => {
  const { retmonTitle, retmonDescription, lang } = useTranslations()
  const templateData = data.markdownRemark

  return (
    <div className="homepage">

      <SEO
        title={`${templateData.frontmatter.title} | ${retmonTitle}`}
        description={retmonDescription}
        lang={lang} />
      <S.Container>
        <S.RowStart>
          <S.Column>
            <S.Title>{templateData.frontmatter.title}</S.Title>
            <S.SubHeader>{templateData.frontmatter.subTitle}</S.SubHeader>
            <br />
            <S.BorderedColumn>
              <Images />
            </S.BorderedColumn>
          </S.Column>
        </S.RowStart>
      </S.Container>
    </div>
  )
}

export const query = graphql`
  query Referances($locale: String!, $title: String!) {
    markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        subTitle
        description
        page
      }
      html
    }
  }
`

export default Referances
