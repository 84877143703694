import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'

export const Container = styled.div``
export const RowStart = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const Column = styled.div`
  width: 100%;
`
export const TableImage = styled.img`
  margin-bottom: 5px;
  width: ${props => props.w || '1.5rem'};
  margin: auto;
`
export const BorderedColumn = styled.div`
  border: 1px solid rgba(109, 94, 94, 0.58039);
  border-radius: 1rem;
  background: white;
  padding: 2rem;
  width: 100%;

  display: flex;
  flex-wrap: wrap;
`

export const Title = styled.h1`
  font-size: ${props => props.size || 'var(--h1)'};
  font-weight: var(--bold);
  margin-bottom: 1rem;
  &:after {
    border-left: 1.5em solid var(--red);
    border-left-color: var(--red);
    display: block;
    content: '';
    height: 0.2rem;
    margin-top: 0.2em;
  }
`
export const SubHeader = styled.h2`
  font-size: ${props => props.Size || 'var(--textInfo)'};
  color: ${props => props.Color || 'var(--info)'};
`
export const Text = styled.p`
  font-size: ${props => props.Size || '16px'};
`

export const DividerForRow = styled.div`
  margin-top: ${props => props.Top || '5em'};
  margin-bottom: ${props => props.Bottom || '5em'};
`

export const RetmonCTAButton = styled.button`
  background: ${props => props.Color || 'var(--red)'};
  color: white;
  padding: 9px;
  width: 10rem;
  transition: all 0.3s ease;
  &:hover {
    background: #ec0753;
    transition: all 0.3s ease;
  }
  &:active,
  &:focus {
    background: var(--redGR);
  }
`
export const Span = styled.span`
  color: ${props => props.Color || 'var(--red)'};
`

export const FlexCardImages = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const CardImages = styled(Img)`
  margin: 0.8rem;
  filter: grayscale(1);
  &:hover {
    filter: grayscale(0);
    transition: filter 0.2s ease-in;
  }
  ${media.lessThan('medium')`
margin-bottom: 2rem;
`}
`

export const CardImageURL = styled.img`
  margin: 0.8rem;
  filter: grayscale(1);
  &:hover {
    filter: grayscale(0);
    transition: filter 0.2s ease-in;
  }
  ${media.lessThan('medium')`
margin-bottom: 2rem;
`}
`

export const CardImageWrapper = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  width: 100%;
  margin: auto;
  text-align: -webkit-center;
  ${media.lessThan('medium')`
flex: 0 0 50%;
max-width: 50%;
`}
`
export const CardImageWrapperLanding = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  width: 100%;
  margin: auto;
  text-align: -webkit-center;
  ${media.lessThan('medium')`
flex: 0 0 100%;
max-width: 100%;
`}
`
export const Margin = styled.div`
  margin-top: 5rem;
  margin-bottom: 5rem;
  ${media.lessThan('medium')`
margin: 0;
`}
`
